<template>
    <div class="container-fluid blog py-2" style="display: flex;
             justify-content: center; /* 水平居中 */
             align-items: center;     /* 垂直居中 */">
        <div style="width: 100%;margin: 30px 0px;">
            <h1 class="title">服务网点</h1>
            <div v-if="spinValue" style="display: flex; justify-content: center; align-items: center;">
                <a-spin>
                    <a-icon slot="indicator" type="loading" style="font-size: 20px" />
                </a-spin>
            </div>
            <div v-else class="point_name">
                {{ servicePointList[0].title }}
            </div>
            <div style="margin: 20px 50px 0px 50px; ">
                <div class="map">
                    <div id="container" class="map">
                    </div>
                </div>
            </div>

        </div>
        <div class="form_background">
            <div v-if="spinValue" style="display: flex; justify-content: center; align-items: center; height: 500px;">
                <a-spin>
                    <a-icon slot="indicator" type="loading" style="font-size: 30px" />
                </a-spin>
            </div>
            <!-- 基础信息 -->
            <a-collapse v-else :bordered="false" accordion @change="changeActivekey">
                <template #expandIcon="props">
                    <a-icon type="caret-right" :rotate="props.isActive ? 90 : 0" />
                </template>
                <a-collapse-panel v-for="(item) in servicePointList" :key="item.id" :header="item.title"
                    :style="customStyle">
                    <div class="col-md-12" style="margin-bottom: 12px;">
                        <span style="font-size:14px; color: #acabbd;font-family: Microsoft YaHei;">
                            联系人：
                        </span>
                        <span style="font-size:14px;color: black;font-family: Microsoft YaHei;">
                            {{ item.linkMan }}
                        </span>
                    </div>
                    <div class="col-md-12" style="margin-bottom: 12px;">
                        <span style="font-size:14px; color: #acabbd;font-family: Microsoft YaHei;">
                            联系电话：
                        </span>
                        <span style="font-size:14px;color: black;font-family: Microsoft YaHei;">
                            <img src="@/assets/callPhone.png"
                                style="height: 18px;margin-bottom: 2px; margin-right: 6px;" alt="">
                            <span style="color: #04b910;">{{ item.serviceLandline }}</span>
                        </span>
                    </div>
                    <div class="col-md-12" style="margin-bottom: 12px;">
                        <span style="font-size:14px; color: #acabbd;font-family: Microsoft YaHei;">
                            服务点地址：
                        </span>
                        <span style="font-size:14px;color: black;font-family: Microsoft YaHei;">
                            <img src="@/assets/toAddress.png"
                                style="height: 18px;margin-bottom: 2px; margin-right: 6px;" alt="">
                            <span style="color: #0060c9;">{{ item.address }}</span>

                        </span>
                    </div>

                </a-collapse-panel>
            </a-collapse>
        </div>

    </div>

</template>
<script>
import { listServicePoint } from "@/request/api";
import AMapLoader from '@amap/amap-jsapi-loader';
export default {
    data() {
        return {
            labelCol: { span: 4 },
            wrapperCol: { span: 19 },
            map: null,
            markerList: [],
            clusterData: null,
            customStyle:
                'background: rgb(255 255 255/var(--tw-bg-opacity));border-radius: 4px;border: 2;overflow: hidden',
            servicePointList: [],
            spinValue: true,
            servicePoint: {
                "address": "",
                "longitude": 121.165666,
                "latitude": 30.886314,
                "linkMan": "",
                "serviceLandline": "",
                "title": ""
            }
        };
    },
    mounted() {
        listServicePoint().then((res) => {
            if (res.data.code == "200") {
                this.servicePointList = res.data.data
                this.servicePoint = this.servicePointList[0]
                this.initMap();
                this.spinValue = false
            }

        });

    },

    methods: {
        initMap() {
            AMapLoader.load({
                key: "5ae01332406925a167770eda0fb8803d",// 申请好的Web端开发者Key，首次调用 load 时必填
                version: "1.4.15",      // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
                plugins: ['AMap.Scale', 'AMap.OverView', 'AMap.ToolBar', 'AMap.MapType', 'AMap.MarkerClusterer'],// 需要使用的的插件列表，如比例尺'AMap.Scale'等
            }).then((AMap) => {
                this.map = new AMap.Map("container", {  //设置地图容器id
                    // viewMode:"3D",    //是否为3D地图模式
                    zoom: 17,           //初始化地图级别
                    center: [this.servicePoint.longitude, this.servicePoint.latitude], //初始化地图中心点位置
                });
                // 构造矢量圆形
                this.markerClusterer()

            }).catch(e => {
                console.log(e);
            })

        },

        markerClusterer() {
            var _that = this
            var markers = []
            var cluster
            var data = _that.servicePoint
            var locationContent = ''
            locationContent = `<div><img style="width:40px;height:45px"  src="${require('@/assets/locationMap.png')}"></img></div>`

            // 海量生成marker点
            var marker = new AMap.Marker({
                position: new AMap.LngLat(data.longitude, data.latitude),
                title: data.title,
                content: locationContent,
                offset: new AMap.Pixel(-15, -15),
            });



            var content = `<div style="width:100%;height:100%;display: flex; flex-direction: column;">
          <div style="width:100%;height:100%;display: flex; flex-direction: row;">
              <div style="font-weight:bold;font-family: Microsoft YaHei;font-size:17px;color: #484848;">${data.title}</div>
              <a style="font-weight:bold;font-family: Microsoft YaHei;font-size:12px;margin:4px;">
              </a>
          </div>
          
          <div style="width:100%;height:100%;display: flex; flex-direction: row;margin: 2px 0px">
              <div style="font-family: Microsoft YaHei;font-size:14px;color: #484848;">联系人：</div>
              <div>${data.linkMan}</div>
          </div>
          
          <div style="width:100%;height:100%;display: flex; flex-direction: row;margin: 2px 0px">
              <div style="font-family: Microsoft YaHei;font-size:14px;color: #484848;">联系电话：</div>
              <div >${data.serviceLandline}</div>
          </div>

          <div style="width:100%;height:100%;display: flex; flex-direction: row;margin: 4px 0px 2px 0px">
              <div style="font-family: Microsoft YaHei;font-size:14px;color: #484848;">地址：</div>
              <div>${data.address}</div>
          </div>


          
        <div style="width:100%;height:100%;display: flex; flex-direction: row;margin: 2px 0px">
              
          </div>
                <img style="height: 70px;width:70px;margin-bottom: 2px; margin-right: 6px;" src="${require('@/assets/zhujinge.jpg')}">
          </div>

          `;
            content.replace(/undefined/, "");

            // 窗体信息
            let infoWindow = new AMap.InfoWindow({
                content: content, //使用默认信息窗体框样式，显示信息内容
                offset: new AMap.Pixel(5, -10),
            });

            infoWindow.open(
                _that.map,
                // 窗口信息的位置
                marker.getPosition(data.lnt, data.lat)
            );

            //监听marker的鼠标划入事件
            AMap.event.addListener(marker, "mouseover", function (e) {
                infoWindow.open(
                    _that.map,
                    // 窗口信息的位置
                    marker.getPosition(data.lnt, data.lat)
                );
            });


            // //监听marker的鼠标划入事件
            // AMap.event.addListener(marker, "mouseout", function (e) {
            //     infoWindow.close(
            //     );
            // });


            //添加监听事件，当前缩放级别
            // AMap.event.addListener(_that.map, "zoomend", function () {
            //     // 关闭信息窗体
            //     _that.map.clearInfoWindow(infoWindow);
            // });
            markers.push(marker);


            this.markerList = markers
            //使用renderClusterMarker属性实现聚合点的完全自定义绘制
            var count = markers.length;
            var _renderClusterMarker = function (context) {
                var factor = Math.pow(context.count / count, 1 / 18);
                var div = document.createElement("div");
                var Hue = 180 - factor * 180;
                var bgColor = "hsla(" + Hue + ",100%,50%,0.7)";
                var fontColor = "hsla(" + Hue + ",100%,20%,1)";
                var borderColor = "hsla(" + Hue + ",100%,40%,1)";
                var shadowColor = "hsla(" + Hue + ",100%,50%,1)";
                div.style.backgroundColor = bgColor;
                var size = Math.round(30 + Math.pow(context.count / count, 1 / 5) * 20);
                div.style.width = div.style.height = size + "px";
                div.style.border = "solid 1px " + borderColor;
                div.style.borderRadius = size / 2 + "px";
                div.style.boxShadow = "0 0 1px " + shadowColor;
                div.innerHTML = context.count;
                div.style.lineHeight = size + "px";
                div.style.color = fontColor;
                div.style.fontSize = "14px";
                div.style.textAlign = "center";
                context.marker.setOffset(new AMap.Pixel(-size / 2, -size / 2));
                context.marker.setContent(div);
            };

            if (this.cluster != null) {
                this.cluster.setMap(null);
            }

            // 点聚合样式
            cluster = new AMap.MarkerClusterer(this.map, markers, {
                gridSize: 45,
                minClusterSize: 25,
                renderClusterMarker: _renderClusterMarker
            });
            this.cluster = cluster
        },
        changeActivekey(key) {
            this.servicePoint = this.servicePointList.find(item => item.id == key)
            this.initMap();
        },
    }

}
</script>

<style lang="less" scoped>
.ant-pro-components-tag-select {
    /deep/ .ant-pro-tag-select .ant-tag {
        margin-right: 24px;
        padding: 0 8px;
        font-size: 14px;
    }
}

.list-articles-trigger {
    margin-left: 12px;
}

.title {
    --tw-text-opacity: 1;
    color: rgb(29 29 29/var(--tw-text-opacity));
    justify-content: center;
    display: flex;
    font-weight: 600;
    font-size: 50px;
}

.point_name {
    --tw-text-opacity: 1 !important;
    color: rgb(29 29 29/var(--tw-text-opacity)) !important;
    font-weight: 600 !important;
    justify-content: center;
    display: flex;
    margin-top: 30px;
    font-size: 20px;
}

.form_background {
    width: 70%;
    margin: 30px 0px;
    border-radius: 1rem;
    border-width: 0;
    --tw-border-opacity: 1;
    border-top: 8px #007aff;
    border-style: solid;
    --tw-bg-opacity: 1;
    background-color: rgb(255 255 255/var(--tw-bg-opacity));
    padding: 3rem;
    --tw-shadow: 0px 20px 25px -5px rgba(64, 63, 118, .1), 0px 10px 10px -5px rgba(64, 63, 118, .04);
    --tw-shadow-colored: 0px 20px 25px -5px var(--tw-shadow-color), 0px 10px 10px -5px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
</style>

<style lang="less" scope>
.map {
    #container {
        border-radius: 4px;
        padding: 0px;
        margin: 0px;
        margin-top: 20px;
        width: 100%;
        height: 500px;
    }

    .amap-logo {
        display: none;
        opacity: 0 !important;
    }

    .amap-copyright {
        opacity: 0;
    }
}
</style>